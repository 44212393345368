import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('@/views/HomeView')
  },
  {
    path: '/catalog',
    name: 'CatalogPage',
    component: () => import('@/views/CatalogPage')
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import('@/views/Product')
  },
  {
    path: '/wishList',
    name: 'WishList',
    component: () => import('@/views/WishList')
  },
  {
    path: '/basket',
    name: 'BasketPage',
    component: () => import('@/views/BasketPage')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/views/Checkout')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News')
  },
  {
    path: '/news/:id',
    name: 'NewsItem',
    component: () => import('@/views/NewsItem')
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('@/views/Thanks')
  },
  {
    path: '/oferta',
    name: 'Oferta',
    component: () => import('@/views/Oferta')
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('@/views/Info')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
})

export default router
