<template>
  <section
      v-if="width > 1024"
      ref="hh"
      class="header"
  >
    <div class="_wrapper">
      <div class="header-content">
        <router-link
            to="/"
            class="logo"
        >
          <div class="_logo-title">
            Современная графика в стиле Пуантилизм
          </div>
          <div class="logo-item">
            Пуантилист мСтич
          </div>
        </router-link>
        <HeaderNavigation/>
        <a href="tel:+78129479982">
          +7 (812) 947 99 82
        </a>
        <HeaderContacts/>
      </div>
      <nav
          v-if="$route.name === 'WishList' || $route.name === 'BasketPage' || $route.name === 'checkout'"
          class="header-title"
      >
        <h1
            v-if="$route.name === 'BasketPage'"
            class="_title"
        >
          Корзина товаров
        </h1>
        <h1
            v-if="$route.name === 'WishList'"
            class="_title"
        >
          Избранные товары
        </h1>
        <h1
            v-if="$route.name === 'checkout'"
            class="_title"
        >
          Оформление заказа
        </h1>
      </nav>
    </div>
  </section>
  <section v-else ref="hh" class="header">
    <div class="_wrapper">
      <div class="header-content">
        <router-link to="/" class="logo">
          Пуантилист <br>
          мСтич
        </router-link>
        <HeaderContacts />
        <div>
          <svg
              v-if="!show"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="setShowBurger(true)"
          >
            <rect
                x="1"
                y="4"
                width="22"
                height="2"
                rx="1"
                fill="white"
            />
            <rect
                x="1"
                y="11"
                width="22"
                height="2"
                rx="1"
                fill="white"
            />
            <rect
                x="1"
                y="18"
                width="22"
                height="2"
                rx="1"
                fill="white"
            />
          </svg>
          <svg
              v-if="show"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="setShowBurger(false)"
          >
            <rect
                x="5.41418"
                y="17.021"
                width="17"
                height="2"
                rx="1"
                transform="rotate(-45 5.41418 17.021)"
                fill="white"
            />
            <rect
                x="6.41418"
                y="5.021"
                width="17"
                height="2"
                rx="1"
                transform="rotate(45 6.41418 5.021)"
                fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="show" class="burger-content" :style="{height : 'calc(100vh - ' + height + 'px)'}">
      <HeaderNavigation/>
      <a href="tel:+78129479982">
        +7 (812) 947 99 82
      </a>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import HeaderNavigation from "@/components/HeaderNavigation";
import HeaderContacts from "@/components/HeaderContacts";

export default {
  name: 'HeaderComponent',
  components: {
    HeaderContacts,
    HeaderNavigation
  },
  computed: {
    ...mapState({
      width: 'displayWidth',
      height: 'headerHeight',
      show: 'showBurger'
    })
  },
  mounted() {
    this.$store.commit('SET_DISPLAY_WIDTH', window.innerWidth)
    window.addEventListener("resize", () => {
      this.$store.commit('SET_DISPLAY_WIDTH', window.innerWidth)
      this.calculateHeaderHeight(this.$route)
    });
  },
  watch: {
    $route: function (val) {
      this.calculateHeaderHeight(val)
    }
  },
  methods: {
    setShowBurger(bool) {
      this.$store.commit('SET_SHOW_BURGER', bool)
    },
    calculateHeaderHeight(route) {
      setTimeout(() => {
        if (route.name === 'CartItem') {
          this.$store.commit('SET_HEADER_HEIGHT', this.$refs.hh.getBoundingClientRect().height + 30)
        } else {
          this.$store.commit('SET_HEADER_HEIGHT', this.$refs.hh.getBoundingClientRect().height)
        }
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  padding: rem(19) 0;
  background: url("~@/assets/webp/headerBackground.webp") center no-repeat;
  background-size: cover;
  color: $color_bg;
  position: relative;

  a {
    cursor: pointer;
    color: $color_bg;
    text-align: center;
  }

  svg {
    cursor: pointer;
  }
}

.logo-item {
  font-family: 'Lainyday', sans-serif;
  font-size: rem(24);
  font-weight: bold;
  text-align: left;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(10);
}

.header-navigation, .header-active {
  display: flex;
  gap: calc(10rem / 16 + (18 - 10) * ((100vw - 390rem / 16) / (1440 - 390)));
  align-items: center;
  flex-shrink: 0;
}


.header-active__wish-list, .header-active__basket {
  display: block;
}

.burger-content {
  padding: rem(60) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-title {
  padding: rem(57) 0 rem(23);
}

@media (max-width: em(1024, 16)) {
  .header-navigation {
    flex-direction: column;
  }
  .logo {
    font-family: 'Lainyday', sans-serif;
    font-size: rem(24);
    font-weight: bold;
  }
}
</style>
