<template>
  <div ref="main" class="container">
    <header>
      <HeaderComponent/>
    </header>
    <main :style="{marginTop : height + 'px'}">
      <router-view/>
    </main>
    <footer>
      <FooterComponent/>
    </footer>
    <StatusBox :statuses="statuses" />
    <TheLoader v-if="show" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
import TheLoader from "@/components/UI/Loader";
import StatusBox from "@/components/UI/StatusBox";

export default {
  components: {
    StatusBox,
    TheLoader,
    FooterComponent,
    HeaderComponent
  },
  computed: {
    ...mapState({
      height: 'headerHeight',
      show: 'showLoader',
      statuses: 'statuses'
    })
  },
  created() {
    this.$store.commit('SET_AXIOS_INSTANCE');
    this.$store.dispatch('fetchAuthToken', {
      username: 'admin',
      password: 'gdso32jk*IO@JK%io8kj32jk'
    });
    this.$store.dispatch('fetchNews')
    this.$store.dispatch('fetchReviews')
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Lainyday';
  src: url('~@/assets/fonts/Lainyday.ttf');
  font-weight: normal;
  font-style: normal;
}

@keyframes pulse {
  from {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1.0);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

* {
  padding: 0;
  margin: 0;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: Montserrat, sans-serif;
}

main {
  position: relative;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a, a:link, a:visited {
  color: $color-bg;
  text-decoration: none;
  display: inline-block;
}

a:hover {
  text-decoration: none;
}


aside, nav, footer, header, section, main {
  display: block;
}

.container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header {
  position: fixed;
  width: 100%;
  z-index: 999;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}


input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background: transparent none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

main{
  flex: 1 1 auto;
  margin-bottom: rem(30);
}
._wrapper {
  padding: 0 calc(20rem / 16 + (140 - 20) * ((100vw - 390rem / 16) / (1440 - 390)));
}

._logo-title {
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(20);
}

._header-links {
  text-transform: uppercase;
  font-weight: 400;
  font-size: rem(12);
  line-height: rem(15);
  color: $color-bg;
}
._footer-fields{
  font-weight: 500;
  font-size: rem(12);
  line-height: rem(15);
}
._active-bold {
  font-style: normal;
  font-weight: 600;
  font-size: rem(14);
  line-height: rem(17);
}

._title {
  font-weight: 700;
  font-size: rem(40);
  line-height: rem(49);
}

._sub-title {
  font-weight: 500;
  font-size: calc(14rem / 16 + (24 - 14) * ((100vw - 390rem / 16) / (1440 - 390)));
  line-height: rem(29);
}

._slim-text {
  font-weight: 400;
  font-size: rem(16);
  line-height: rem(20);
}

._main-text {
  font-weight: 500;
  font-size: rem(20);
  line-height: rem(24.38);
}
._footer-description{
  font-weight: 400;
  font-size: rem(10);
  line-height: rem(12);
}
._footer-little-text{
  font-weight: 400;
  font-size: rem(8);
}
._bold-text{
  font-weight: 700;
  font-size: rem(14);
  line-height: rem(17);
  text-transform: uppercase;
}
._cart-item-description{
  font-weight: 500;
  font-size: rem(14);
  line-height: rem(17);
}
._table-item-bold{
  font-weight: 600;
  font-size: rem(10);
  line-height: rem(12);
  text-transform: uppercase;
}

.wp-block-group__inner-container {
  display: flex;
  flex-direction: column;
  gap: rem(10);
}

.wp-about-image {
  figure {
    display: flex;
    justify-content: center;
  }
}

.has-text-align-center {
  text-align: center;
  font-size: rem(12);
  font-weight: bold;
}
</style>
