export const catalog = {
    state: {
        newGoods: [],
        goods: [],
        searchingGoods: []
    },
    getters: {
        goodsCount: (state) => {
            return state.searchingGoods.length
        }
    },
    mutations: {
        SET_NEW_GOODS(state, goods) {
            state.newGoods = goods
        },
        SET_GOODS(state, goods) {
            state.goods = goods
            state.searchingGoods = goods
        },
        SET_SEARCHING_GOODS(state, searching) {
            state.searchingGoods = state.goods.filter(elem => {
                return elem.name.toLowerCase().indexOf(searching.toLowerCase(), 0) !== -1
            })
        }
    },
    actions: {
        async fetchNewGoods({rootState, commit}) {
            try {
                commit('SET_SHOW_LOADER', true, {root: true})
                if (rootState.jwt !== '') {
                    const response = await rootState.axiosInstance.get('wc/v3/products', {
                        headers: {
                            Authorization: `Bearer ${rootState.jwt}`,
                        },
                        params: {
                            orderby: 'date',
                            per_page: 3,
                            category: 15
                        }
                    })
                    commit('SET_NEW_GOODS', response.data);
                }
            } catch (e) {
                console.log('Ошибка')
            } finally {
                commit('SET_SHOW_LOADER', false, {root: true})
            }
        },
        async fetchGoods({rootState, commit}) {
            try {
                commit('SET_SHOW_LOADER', true, {root: true})
                if (rootState.jwt !== '') {
                    const response = await rootState.axiosInstance.get('wc/v3/products', {
                        headers: {
                            Authorization: `Bearer ${rootState.jwt}`,
                        },
                        params: {
                            per_page: 100,
                            category: 15,
                            order: 'desc'
                        }
                    })
                    commit('SET_GOODS', response.data)
                    commit('SET_SEARCHING_GOODS', '')
                }
            } catch (e) {
                console.log('Ошибка')
            } finally {
                commit('SET_SHOW_LOADER', false, {root: true})
            }
        }
    },
    namespaced: true
}
