<template>
  <div class="header-active">
    <router-link
        to="/wishList"
        class="header-active__wish-list"
        @click="closeBurger"
    >
      <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M12.62 20.8101C12.28 20.9301 11.72 20.9301 11.38 20.8101C8.48 19.8201 2 15.6901 2 8.6901C2 5.6001 4.49 3.1001 7.56 3.1001C9.38 3.1001 10.99 3.9801 12 5.3401C13.01 3.9801 14.63 3.1001 16.44 3.1001C19.51 3.1001 22 5.6001 22 8.6901C22 15.6901 15.52 19.8201 12.62 20.8101Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
      </svg>
      <span class="counter">
        {{ wishLength }}
      </span>
    </router-link>
    <router-link
        to="/basket"
        class="header-active__basket"
        @click="closeBurger"
    >
      <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M17.7687 21.3125C17.0375 21.3125 16.4562 20.7313 16.4562 20C16.4562 19.2687 17.0375 18.6875 17.7687 18.6875C18.5 18.6875 19.0812 19.2687 19.0812 20C19.0812 20.7313 18.4812 21.3125 17.7687 21.3125ZM7.26875 21.3125C6.5375 21.3125 5.95625 20.7313 5.95625 20C5.95625 19.2687 6.5375 18.6875 7.26875 18.6875C8 18.6875 8.58125 19.2687 8.58125 20C8.58125 20.7313 7.98125 21.3125 7.26875 21.3125ZM7.0625 17.5625C6.06875 17.5625 5.20625 16.775 5.09375 15.7812L3.81875 6.81875L3.0875 2.6375C3.03125 2.1875 2.6375 1.8125 2.20625 1.8125H1.25C0.93125 1.8125 0.6875 1.56875 0.6875 1.25C0.6875 0.93125 0.93125 0.6875 1.25 0.6875H2.20625C3.2 0.6875 4.08125 1.475 4.2125 2.46875L4.94375 6.65L6.21875 15.6312C6.25625 16.0625 6.65 16.4375 7.0625 16.4375H19.25C19.5687 16.4375 19.8125 16.6812 19.8125 17C19.8125 17.3188 19.5687 17.5625 19.25 17.5625H7.0625ZM8 14.5625C7.7 14.5625 7.45625 14.3375 7.4375 14.0375C7.41875 13.8875 7.475 13.7375 7.56875 13.625C7.6625 13.5125 7.8125 13.4375 7.94375 13.4375L18.125 12.6875C18.575 12.6875 18.9688 12.3313 19.025 11.9L20.2062 5.15C20.2437 4.83125 20.15 4.49375 19.9625 4.2875C19.8312 4.1375 19.6625 4.0625 19.4562 4.0625H6.5C6.18125 4.0625 5.9375 3.81875 5.9375 3.5C5.9375 3.18125 6.18125 2.9375 6.5 2.9375H19.4937C20.0187 2.9375 20.4875 3.1625 20.8437 3.5375C21.2375 3.9875 21.425 4.64375 21.35 5.3L20.1687 12.05C20.0562 13.025 19.175 13.7938 18.2 13.7938L8.075 14.5438C8.0375 14.5625 8.01875 14.5625 8 14.5625Z"
            fill="white"
        />
      </svg>
      <span class="counter">
        {{ cartLength }}
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'HeaderContacts',
  computed: {
    ...mapGetters('checkout', {
      cartLength: 'cartCount',
      wishLength: 'wishCount'
    })
  },
  methods: {
    closeBurger() {
      this.$store.commit('SET_SHOW_BURGER', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-active__basket, .header-active__wish-list {
  position: relative;
}

.counter {
  position: absolute;
  top: rem(-6);
  right: rem(-6);
  width: rem(12);
  height: rem(12);
  border-radius: 50%;
  background-color: #FFFFFF;
  color: #1D1D1B;
  text-align: center;
  font-size: rem(10);
}
</style>
