<template>
  <section class="footer">
    <div class="_wrapper">
      <div class="footer__first-box">
        <div class="footer-contacts">
          <p class="_phone _footer-fields">
            Номер телефона:
          </p>
          <p class="_email _footer-fields">
            E-mail
          </p>
          <a
              href="tel:+79219496315"
              class="_active-bold _tel-one"
          >
            +7 (921) 949 63 15
          </a>
          <a
              href="tel:+7(812)9479982"
              class="_active-bold _tel-two"
          >
            +7 (812) 947 99 82
          </a>
          <a
              class="_active-bold _tel-tree"
              href="mailto:info@kartinn.ru"
          >
            info@kartinn.ru
          </a>
          <a
              class="_active-bold _tel-four"
              href="mailto:mStich@kartinn.ru"
          >
            mStich@kartinn.ru
          </a>
        </div>
        <div class="footer-active">
          <nav>
            <router-link to="/catalog" class="_header-links">
              галерея картин
            </router-link>
            <router-link to="/about" class="_header-links">
              об авторе
            </router-link>
          </nav>
        </div>
      </div>
      <div class="footer__last-box">
        <div class="footer__logo">
          <router-link to="/" class="logo-footer">
            <span>
              Пуантилист
            </span>
            <span>
              мСтич
            </span>
          </router-link>
          <p class="footer__description">
            Интернет магазин современной графики в стиле Пуантилизм.<br> Репродукции и оригиналы картин, арт-сувениры,
            тату-эскизы, аэрография на авто.<br>Можно заказать автопортрет, баннер для дачи и много другое.<br>
            Закажите себе индивидуальную картину нужного размера на холсте или автопортрет, репродукцию из каталога
            сайта,<br>
            полотно для зонирования пространства или баннер для дома / дачи и многое другое
          </p>
        </div>
        <div class="footer-info">
          <div class="socials">
            <a href="https://vk.com/id736021007" target="_blank">
              <img src="@/assets/svg/vk.svg" alt="vk"/>
            </a>
            <a href="https://t.me/pointillistmStich" target="_blank">
              <img src="@/assets/svg/tg.svg" alt="vk"/>
            </a>
          </div>
          <router-link to="/oferta" class="info-link">
            Публичная оферта
          </router-link>
          <router-link to="/info" class="info-link">
            Реквизиты
 </router-link>
          <router-link to="/privacy-policy.php" class="info-link">
            Политика конфиденциальности
         </router-link>
        </div>
        <div class="footer-info copy">
          ©kartinn.ru<br><br>
          Любое использование либо копирование материалов или подборки материалов сайта, элементов дизайна и оформления
          допускается лишь с разрешения правообладателя и только со ссылкой на источник: kartinn.ru
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped lang="scss">
.footer {
  padding: rem(60) 0;
  background: $color_main;

  a {
    color: $color_bg;
  }
}

.footer__first-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: rem(40);
  border-bottom: 1px solid #77858d1a;

  ._footer-fields {
    color: $color_gray;
  }

  a {
    cursor: pointer;
  }

  .footer-contacts {
    flex: 1 1 83%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
    'phone phone email email'
    'telOne telTwo telTree telFour';
    row-gap: rem(15);

    ._phone {
      grid-area: phone;
    }

    ._email {
      grid-area: email;
    }

    ._tel-one {
      grid-area: telOne;
    }

    ._tel-two {
      grid-area: telTwo;
    }

    ._tel-tree {
      grid-area: telTree;
    }

    ._tel-four {
      grid-area: telFour;
    }
  }
}

.footer-active {
  flex: 1 0 17%;

  nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: rem(20);
  }
}

.footer__last-box {
  padding-top: rem(40);
  display: flex;
  justify-content: space-between;
  gap: rem(10);
}

.footer__logo {
  display: flex;
  gap: rem(20);

  p {
    color: $color_gray;
    max-width: rem(332);
    font-size: rem(12);
  }
}

.logo-footer {
  font-family: 'Lainyday', sans-serif;
  font-size: rem(24);
  font-weight: bold;
  display: flex;
  gap: rem(20);
}

.footer-info {
  display: flex;
  flex-direction: column;
  gap: rem(15);
}

.socials {
  display: flex;
  justify-content: space-between;
  gap: rem(33);
  width: 100%;
}

.info-link {
  font-size: rem(12);
}

.copy {
  max-width: rem(228);
  color: white;
  font-size: rem(8);
}

@media (min-width: em(1024, 16)) {
  ._wrapper {
    padding: 0 calc(70rem / 16 + (140 - 70) * ((100vw - 1024rem / 16) / (1440 - 1024)));
  }
}

@media (max-width: em(1280, 16)) {
  .footer__first-box {
    .footer-contacts {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
      'phone email'
      'telOne telTree'
      'telTwo telFour';
    }
  }
}

@media (max-width: em(768, 16)) {
  .copy {
    text-align: center;
  }
  .footer {
    padding: rem(40) 0
  }
  .footer__first-box {
    flex-direction: column;
    align-items: center;
    gap: rem(40);
    padding-bottom: rem(30);

    .footer-contacts {
      grid-template-columns: 1fr;
      grid-template-areas: 'phone''telOne''telTwo''email''telTree''telFour';
      justify-items: center;
    }
  }
  .footer__last-box {
    flex-direction: column;
    align-items: center;
    padding-top: rem(30);
    gap: rem(30);
  }
  .footer__logo {
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }
  }
  .footer-info {
    flex-direction: column-reverse;
    align-items: center;
  }
}
</style>
