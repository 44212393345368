<template>
  <nav class="header-navigation">
    <router-link
        to="/catalog"
        class="_header-links"
        @click="closeBurger"
    >
      галерея картин
    </router-link>
    <router-link
        to="/news"
        class="_header-links"
        @click="closeBurger"
    >
      новости
    </router-link>
    <router-link
        to="/about"
        class="_header-links"
        @click="closeBurger"
    >
      об авторе
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'HeaderNavigation',
  methods: {
    closeBurger() {
      this.$store.commit('SET_SHOW_BURGER', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: em(1024, 16)) {
  .header-navigation {
    gap: rem(40) !important;
  }
  ._header-links {
    font-size: rem(16);
  }
}
</style>
