export const product = {
    state: {
        product: {},
        videoProduct: {}
    },
    getters: {
        isProductExist: (state) => {
            let flag = false
            if (Object.keys(state.product).length > 0) {
                flag = true
            }
            return flag
        },
        productTitle: (state, getters) => {
            let title = ''
            if (getters.isProductExist) {
                title = state.product.name
            }
            return title
        },
        productPrice: (state, getters) => {
            let price = ''
            if (getters.isProductExist) {
                price = state.product.price
            }
            return price
        },
        productDescription: (state, getters) => {
            let description = ''
            if (getters.isProductExist) {
                description = state.product.description.replace(/( |<([^>]+)>)/ig, ' ')
            }
            return description
        },
        productImages: (state, getters) => {
            let images = []
            if (getters.isProductExist) {
                images = state.product.images
            }
            return images
        },
        isVariationsExist: (state, getters) => {
            return getters.isProductExist && state.product.variations.length > 0
        },
        variations: (state, getters) => {
            let variations = []
            if (getters.isVariationsExist) {
                variations = state.product.variations
            }
            return variations
        },
        isVideoExist: (state) => {
            let flag = false
            if (Object.keys(state.videoProduct).length > 0) {
                flag = true
            }
            return flag
        },
        videoPrice: (state, getters) => {
            let price = ''
            if (getters.isVideoExist) {
                price = state.videoProduct.price
            }
            return price
        },
        videoTitle: (state, getters) => {
            let title = ''
            if (getters.isVideoExist) {
                title = state.videoProduct.name
            }
            return title
        },
        videoImage: (state, getters) => {
            let images = ''
            if (getters.isVideoExist) {
                images = state.videoProduct.images[0].src
            }
            return images
        }
    },
    mutations: {
        SET_PRODUCT(state, product) {
            state.product = product
        },
        SET_VIDEO_PRODUCT(state, product) {
            state.videoProduct = product
        }
    },
    actions: {
        async fetchProduct({rootState, commit, dispatch}, id) {
            commit('SET_SHOW_LOADER', true, {root: true})
            await rootState.axiosInstance.get(`wc/v3/products/${id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.jwt}`,
                },
            }).then(response => {
                console.log(response)
                if (response.data.categories.length > 0) {
                    if (response.data.categories[0].id === 15) {
                        commit('SET_PRODUCT', response.data)
                        commit('SET_VIDEO_PRODUCT', {})
                        if (response.data.cross_sell_ids.length > 0) {
                            dispatch('fetchProduct', response.data.cross_sell_ids[0])
                        }
                    } else {
                        commit('SET_VIDEO_PRODUCT', response.data)
                    }
                } else {
                    commit('SET_PRODUCT', response.data)
                }
                commit('SET_SHOW_LOADER', false, {
                    root: true
                })
            });
        }
    },
    namespaced: true
}