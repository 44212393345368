<template>
  <div class="loader">
    <img src="@/assets/load.gif" alt="Загрузка">
  </div>
</template>

<script>
export default {
  name: 'TheLoader'
}
</script>

<style scoped lang="scss">
.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9999;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>