import axios from "axios"
import { md5 } from "js-md5";

export const checkout = {
    state: {
        cart: localStorage.getItem('kartinn_cart') !== null ? JSON.parse(localStorage.getItem('kartinn_cart')) : [],
        wishlist: localStorage.getItem('kartinn_wish') !== null ? JSON.parse(localStorage.getItem('kartinn_wish')) : [],
        orderBox: sessionStorage.getItem('orderBox') !== null ? JSON.parse(sessionStorage.getItem('orderBox')) : {}
    },
    getters: {
        wishCount (state) {
            return state.wishlist.length
        },
        cartCount (state) {
            return state.cart.length
        },
        cartTotal (state) {
            return state.cart.reduce((acc, cur) => acc + cur.price, 0)
        }
    },
    mutations: {
        ADD_TO_CART(state, item) {
            state.cart.push(item)
            localStorage.setItem('kartinn_cart', JSON.stringify(state.cart))
        },
        REMOVE_FROM_CART(state, id) {
            state.cart = state.cart.filter(elem => {
                return elem.id !== id
            })
            localStorage.setItem('kartinn_cart', JSON.stringify(state.cart))
        },
        CLEAR_CART(state) {
            state.cart = []
            localStorage.removeItem('kartinn_cart')
        },
        ADD_TO_WISHLIST(state, item) {
            state.wishlist.push(item)
            localStorage.setItem('kartinn_wish', JSON.stringify(state.wishlist))
        },
        REMOVE_FROM_WISHLIST(state, id) {
            state.wishlist = state.wishlist.filter(elem => {
                return elem.id !== id
            })
            localStorage.setItem('kartinn_wish', JSON.stringify(state.wishlist))
        },
        SET_ORDER_BOX(state, item) {
            state.orderBox = item
            sessionStorage.setItem('orderBox', JSON.stringify(state.orderBox))
        },
        CLEAR_ORDER_BOX(state) {
            state.orderBox = {}
        }
    },
    actions: {
        async createOrderComment({rootState}, commentInfo) {
            await rootState.axiosInstance.post('wc/v3/orders/'+ commentInfo.id +'/notes/?consumer_key=ck_10ac7ca290ac5b048847f2dd97eafaa3412120c3&consumer_secret=cs_c22df3d0eaa60ff216317849a9e3112cbc2c77a6', commentInfo.data)
        },
        async sendOrder({state, rootState, commit, dispatch}) {
            const response = await rootState.axiosInstance.post('wc/v3/orders/?consumer_key=ck_10ac7ca290ac5b048847f2dd97eafaa3412120c3&consumer_secret=cs_c22df3d0eaa60ff216317849a9e3112cbc2c77a6', state.orderBox.order, {
                headers: {
                    Authorization: `Bearer ${rootState.jwt}`,
                }
            })
            if (response.status === 201) {
                if (state.orderBox.comment.length > 0) {
                    dispatch('createOrderComment', {
                        id: response.data.id,
                        data: {
                            note: state.orderBox.comment
                        }
                    })
                }
                commit('CLEAR_CART')
                commit('CLEAR_ORDER_BOX')
            }
        },
        async createPayment({commit}, obj) {
            const id = 'karinn.ru'
            const pwd = 'Ai8SBMZzYAcbA73z2Lu0';
            const description = 'Покупка на сайте kartinn.ru'
            const sign = md5(`${id}:${obj.sum}::${pwd}`)
            const url = `https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=${id}&OutSum=${obj.sum}&Description=${description}&SignatureValue=${sign}`
            location.href = url
        }
    },
    namespaced: true
}