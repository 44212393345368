<template>
  <div class="status-box">
    <div
        v-for="(item, index) in statuses"
        :key="index"
        class="status"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusBox',
  props: {
    statuses: {
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.status-box {
  position: fixed;
  top: rem(80);
  right: rem(20);
  max-width: rem(200);
  z-index: 9999;
}

.status {
  background-color: $color_main;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: rem(20);
  font-size: rem(16);
  margin-bottom: rem(20);
}
</style>
