import {createStore} from 'vuex'
import {catalog} from "@/store/catalog";
import {product} from "@/store/product";
import {checkout} from "@/store/checkout";
import axios from 'axios';

export default createStore({
    state: {
        displayWidth: 0,
        headerHeight: 0,
        showBurger: false,
        axiosInstance: null,
        jwt: '',
        homeHeader: '',
        homeText: '',
        aboutHeader: '',
        aboutText: '',
        aboutImage: '',
        showLoader: false,
        news: [],
        newsItem: {},
        statuses: [],
        reviews: [],
        reviewText: '',
        reviewName: '',
        reviewEmail: ''
    },
    getters: {
        isNewsItemExist (state) {
            return Object.keys(state.newsItem).length > 0
        }
    },
    mutations: {
        SET_DISPLAY_WIDTH(state, width) {
            state.displayWidth = width;
        },
        SET_HEADER_HEIGHT(state, height) {
            state.headerHeight = height
        },
        SET_SHOW_BURGER(state, show) {
            state.showBurger = show
        },
        SET_AXIOS_INSTANCE(state) {
            state.axiosInstance = axios.create({
                //baseURL: '/wp-json/'
                baseURL: 'https://kartinn-mstich.ru/wp-json/'
            });
        },
        SET_JWT(state, jwt) {
            state.jwt = jwt;
        },
        SET_HOME_HEADER(state, header) {
            state.homeHeader = header
        },
        SET_HOME_TEXT(state, text) {
            state.homeText = text
        },
        SET_ABOUT_HEADER(state, header) {
            state.aboutHeader = header
        },
        SET_ABOUT_TEXT(state, text) {
            state.aboutText = text
        },
        SET_ABOUT_IMAGE(state, image) {
            state.aboutImage = image
        },
        SET_SHOW_LOADER(state, show) {
            state.showLoader = show
        },
        SET_NEWS(state, news) {
            state.news = news
        },
        SET_NEWS_ITEM(state, item) {
            state.newsItem = item
        },
        ADD_STATUS(state, item) {
            state.statuses.push(item);
            setTimeout(() => {
                const index = state.statuses.indexOf(item);
                state.statuses.splice(index, 1);
            }, 3000);
        },
        SET_REVIEWS(state, reviews) {
            state.reviews = reviews
        },
        SET_REVIEW_TEXT(state, text) {
            state.reviewText = text
        },
        SET_REVIEW_NAME(state, name) {
            state.reviewName = name
        },
        SET_REVIEW_EMAIL(state, email) {
            state.reviewEmail = email
        }
    },
    actions: {
        generateToken({state, commit, dispatch}, profileParams) {
            state.axiosInstance.post('jwt-auth/v1/token', profileParams).then((response) => {
                sessionStorage.setItem('jwt', response.data.token);
                commit('SET_JWT', response.data.token);
                dispatch('catalog/fetchNewGoods', null, {root: true});
                dispatch('catalog/fetchGoods', null, {root: true});
            });
        },
        async fetchAuthToken({state, commit, dispatch}, profile) {
            try {
                commit('SET_SHOW_LOADER', true)
                if (sessionStorage.getItem('jwt') != null) {
                    commit('SET_JWT', sessionStorage.getItem('jwt'));
                    dispatch('catalog/fetchNewGoods', null, {root: true});
                    dispatch('catalog/fetchGoods', null, {root: true});
                } else {
                    await dispatch('generateToken', profile)
                }
            } catch (e) {
                await dispatch('generateToken', profile)
            } finally {
                commit('SET_SHOW_LOADER', false)
            }
        },
        async fetchHomeText ({state, commit}) {
            try {
                commit('SET_SHOW_LOADER', true)
                const response = await state.axiosInstance.get('wp/v2/posts?', {
                    params: {
                        slug: 'home'
                    }
                })
                commit('SET_HOME_HEADER', response.data[0].title.rendered)
                commit('SET_HOME_TEXT', response.data[0].content.rendered)
            }
            catch (e) {
                console.log('Ошибка')
            }
            finally {
                commit('SET_SHOW_LOADER', false)
            }
        },
        async fetchAboutText ({state, commit}) {
            try {
                commit('SET_SHOW_LOADER', true)
                const response = await state.axiosInstance.get('wp/v2/posts?_embed', {
                    params: {
                        slug: 'puant'
                    }
                })
                commit('SET_ABOUT_HEADER', response.data[0].title.rendered)
                commit('SET_ABOUT_TEXT', response.data[0].content.rendered)
                commit('SET_ABOUT_IMAGE', response.data[0]["_embedded"]["wp:featuredmedia"][0]["source_url"])
            }
            catch (e) {
                console.log('Ошибка')
            }
            finally {
                commit('SET_SHOW_LOADER', false)
            }
        },
        async fetchNews ({state, commit}) {
            try {
                commit('SET_SHOW_LOADER', true)
                const response = await state.axiosInstance.get('wp/v2/posts?_embed&categories=22')
                commit('SET_NEWS', response.data)
            }
            catch (e) {
                console.log('Ошибка')
            }
            finally {
                commit('SET_SHOW_LOADER', false)
            }
        },
        async fetchNewsItem ({state, commit}, id) {
            try {
                commit('SET_SHOW_LOADER', true)
                const response = await state.axiosInstance.get('wp/v2/posts/' + id + '?_embed')
                commit('SET_NEWS_ITEM', response.data)
            }
            catch (e) {
                console.log('Ошибка')
            }
            finally {
                commit('SET_SHOW_LOADER', false)
            }
        },
        async fetchReviews ({state, commit}) {
            try {
                commit('SET_SHOW_LOADER', true)
                const response = await state.axiosInstance.get('wp/v2/posts?_embed&categories=26')
                commit('SET_REVIEWS', response.data)
            }
            catch (e) {
                console.log('Ошибка')
            }
            finally {
                commit('SET_SHOW_LOADER', false)
            }
        },
        async sendReviews ({state, commit}) {
            try {
                commit('SET_SHOW_LOADER', true)
                const response = await state.axiosInstance.post('/wp/v2/comments', {
                    author_name: state.reviewName,
                    content: state.reviewText,
                    author_email: state.reviewEmail,
                    post: 549
                }, {
                    headers: {
                        Authorization: `Bearer ${state.jwt}`,
                    }
                })
                commit('ADD_STATUS', 'Благодарим за отзыв!')
                commit('SET_REVIEW_TEXT', '')
                commit('SET_REVIEW_EMAIL', '')
                commit('SET_REVIEW_EMAIL', '')
            }
            catch (e) {
                console.log('Ошибка')
            }
            finally {
                commit('SET_SHOW_LOADER', false)
            }
        }
    },
    modules: {
        catalog: catalog,
        product: product,
        checkout: checkout
    }
})
